<template>
  <div class="guarantee__user-role-page">
    <div class="user-role-select-box">
      <h2>选择角色</h2>
      <div class="user-role-tips">身份信息一经选定不可修改，请谨慎选择！</div>
      <div class="user-role-box">
        <div
          class="user-role-item agent-role"
          :class="activeUserRole === 'R_TENDER_AGENT' ? 'user-role-item--active' : ''"
          @click="onUserRoleClick('R_TENDER_AGENT')"
        >
          <div class="text-content">招标代理</div>
        </div>
        <div
          class="user-role-item bidder-role"
          :class="activeUserRole === 'R_BUSINESS' ? 'user-role-item--active' : ''"
          @click="onUserRoleClick('R_BUSINESS')"
        >
          <div class="text-content">投标人</div>
        </div>
      </div>
      <div class="enter-box">
        <el-button type="primary" @click="onEnterPlatform" :disabled="activeUserRole === ''">进入平台</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserRolePage',
  components: {},
  data () {
    return {
      activeUserRole: ''
    }
  },
  computed: {},
  created () {
  },
  mounted () {
  },
  methods: {
    onUserRoleClick (userRoleType) {
      this.activeUserRole = userRoleType
    },
    onEnterPlatform () {
      this.$confirm('当前身份信息一经选定不能修改，请确认是否提交？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.requestUpdateUserRole()
        })
        .catch(() => {
        })
    },
    requestUpdateUserRole () {
      let params = {
        roleCode: this.activeUserRole
      }
      this.$baseAppApi.USER.fetchUserSelectRole(params)
        .then(async res => {
          if (res.code === 0) {
            await this.$rootMethods.APP_CONTEXT.initAppContext()
              .finally(() => {
                console.log('【base-platform】【Platform.vue】子应上下文数据：', this.$rootState)
                this.$store.state.rootMicroAppData = this.$rootState
                console.log('【base-platform】【Platform.vue】上下文执行完成，渲染子应用')
                window.location.href = this.$store.state.defaultMenuInfo.defaultUrl
              })
          }
        })
    }
  }
}
</script>
<style lang="scss">
.guarantee__user-role-page {
  background: url(../assets/images/register/gtm-register-bg@2x.png) center center no-repeat;
  background-size: 100% 100%;
  padding: 4.52rem 0 20px;
  height: 100%;
  box-sizing: border-box;

  .user-role-select-box {
    width: 56%;
    height: 520px;
    margin: auto;
    margin-top: 50px;
    background: #fff;
    padding: 10px 60px 60px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    // text-align: center;
    h2 {
      font-size: 30px;
      font-weight: 600;
      color: #444444;
      margin: 30px 0;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }

    .user-role-tips {
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #ff0000;
      line-height: 24px;
      text-align: center;
    }

    .user-role-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 50px;

      .user-role-item {
        width: 426px;
        height: 280px;
        border: 2px solid #e2e2e2;
        border-radius: 9px;
        cursor: pointer;

        &:hover {
          border-color: #2695ea;
        }

        .text-content {
          font-weight: bold;
          font-size: 26px;
          color: #2695ea;
          text-align: center;
          margin-top: 200px;
        }
      }

      .user-role-item--active {
        border-color: #2695ea;
      }

      .agent-role {
        background: url(../assets/images/userRole/agent-role.png) center center no-repeat;
        background-size: 100% 100%;
        margin-right: 40px;
      }

      .bidder-role {
        background: url(../assets/images/userRole/bidder-role.png) center center no-repeat;
        background-size: 100% 100%;
      }
    }

    .enter-box {
      text-align: center;
      margin-top: 54px;
    }
  }
}
</style>
